<template>
  <v-row>
    <!-- Start Single Team Area  -->
    <!--<v-col
      lg="4"
      md="4"
      sm="6"
      cols="6"
      v-for="team in teamContent"
      :key="team.id"
    >-->
    <v-col lg="4" md="4" sm="4" cols="6" order="2" order-md="1"
     v-for="team in teamContent"
     :key="team.id">
      <div class="team">
        <div class="thumbnail">
          <img :src="team.src" alt="Team Images" />
        </div>
        <div class="content">
          <h4 class="title">{{ team.title }}</h4>
          <p class="designation">{{ team.designation }}</p>
        </div>   
        <!--<SocialIcon />-->      
      </div>
    </v-col>
    <!-- End Single Team Area  -->
  </v-row>
</template>    

<script>
  import SocialIcon from "../../components/social/Social";
  export default {
    components: {
      SocialIcon,
    },
    data() {
      return {
        teamContent: [
                   {
            id: 1,
            src: require("../../assets/images/team/team-1.jpg"),
            title: "Muhammad Azim",
            designation: "Cyber Tarzan",
          },
          {
            id: 2,
            src: require("../../assets/images/team/team-3.jpg"),
            title: "Muhammad Abbas",
            designation: "Cyber Tarzan",
          },
          {
            id: 3,
            src: require("../../assets/images/team/team-2.jpg"),
            title: "Abdul Rab",
            designation: "Cyber Tarzan",
          }          
        ],
      };
    },
  };
</script>
