<template>
  <div>
    <!-- Start Header Area -->
     <HeaderOnePage>
      <img
        slot="logo"
        src="../assets/images/logo/final_logo.png" 
                  
      />   
    </HeaderOnePage>
  
    <!-- End Header Area -->
   
    <!-- Start Slider Area -->    
    <div class="rn-slider-area poss_relative" id="home">
        <SliderFiveCyberTarzan/>
     
    </div>    
    <!-- End Slider Area -->
    <!-- Start About Area  -->
    <div class="about-area bg_color--5" id="about" >
         
      <div class="about-wrapper">
        <About>
          <img
            slot="thum-img"
            class="w-100"
            src="../assets/images/about/about_image.jpg"
            alt="About Images"
          />
        </About>
      </div>
    </div>

    <!-- End About Area  -->    
    <div
      class="rn-service-area service-area rn-section-gap bg_color--1 bg_image--3" 
      id="service">
      
      <v-container>
        <v-row>
          <v-col cols="12">
             <div class="section-title section-title--2 text-center mb--30">
             
                <h2 class="heading-title">WHAT WE DO</h2>
              </div>
          </v-col>
        </v-row>
        <ServiceThree />
      </v-container>
    </div>
   

    
    <!-- Start Counterup Area -->   
    <div
      class="rn-countreupup-area pt--80 pb--120 bg_image bg_image--17 bg-position-fixed theme-text-white"
      data-black-overlay="7"
    >     
      <v-container>        
        <CounterTwo />
      </v-container>
    </div>
    <!-- End Counterup Area -->
   

    <!-- Start team Area  -->
    <div class="rn-team-area rn-section-gap bg_color--1" id="team" style="background-color:#212121;">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="section-title section-title--2 text-center mb--30"
            >
              
              <h2 class="heading-title theme-gradient">MEET OUR TEAM </h2>
              <p>
               -WE ARE STRONGER- 
              </p>
            </div>
          </v-col>
        </v-row>
        <TeamThree />
      </v-container>
    </div>
    <!-- End team Area  -->

    <!-- Start Contact Area  -->
    <div class="rn-contact-area rn-section-gap  bg_color--5" id="contact">
 
      <div class="contact-form--1">
        <v-container>
          <Contact>            
            <img
              slot="contact-img"
              class="w-100"
              src="../assets/images/about/about-6.jpg"
              alt="trydo"
            />
          </Contact>
        </v-container>
      </div>
    </div>
    <!-- End Contact Area  -->

   
    <!-- Start Footer Area  -->
        <Footer />
    <!-- End Footer Area  -->
  </div>
</template>
<script>
  import HeaderOnePage from "../components/header/HeaderOnePage";  
  import AboutCyberTarzan from "../components/about/aboutCyberTarzan";
  
  
  import About from "../components/about/About";
  import ServiceThree from "../components/service/ServiceThree";
  import SliderFiveCyberTarzan from "../components/slider/sliderFiveCyberTarzan";  
  import CounterTwo from "../components/counter/CounterTwo";  
  import TeamThree from "../components/team/TeamThree"; 
     
  import Testimonial from "../components/testimonial/Testimonial";  
  import Contact from "../components/contact/Contact";
  import Footer from "../components/footer/Footer";

  export default {
    components: {
      HeaderOnePage,
      
      AboutCyberTarzan,
      About,      
      ServiceThree,
      CounterTwo,
      TeamThree,
      SliderFiveCyberTarzan,
      
      Testimonial,
      Contact,
      Footer,
    },
    data() {
       return {
        serviceImg: require("../assets/images/bg/bg-image-6.9b165598.jpg"),
      };
    },
  };
</script>
<style scoped>
.bg_image--28{
background-image: url(../assets/images/bg/bg-image-28.png);
}
</style>
