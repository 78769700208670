<template>
  <v-row class="row--35" align="center">
    <v-col lg="6" md="6" cols="12">
      <div class="thumbnail">
        <img
          class="w-100"
          src="../../assets/images/about/about_image.jpg"
          alt="About Images"
        />
      </div>
    </v-col>
    <v-col lg="6" md="6" cols="12">
      <div class="about-inner inner">
        <div class="section-title text-left">
          <h2 >We’r Full Fledged Digital Agency Creating Awesome Things</h2>
          <br/>
          <div class="section-title section-title--2 text-center mb--30">
              <h4>-WE LOVE WHAT WE DO-</h4>
            </div> 
          <p>
            We develop innovative and creative products and services that provide total communication and information solutions. 
              Our emphasis is on offering a high degree of product user friendliness through a positive, creative and focused company staff.
          </p>
        </div>
        <div class="rn-accordion mt--20">
          
        </div>

             </div>
    </v-col>
  </v-row>
</template>
<script>
  import Accordion from "../../components/accordion/Accordion";
  export default {
    components: {
      Accordion,
    },
    data() {
      return {};
    },
  };
</script>
