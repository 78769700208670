
<template>
  <div>   
      <VisibilitySensor @change="onChange">
    <VueSlickCarousel>          
      <div class="slide slide-style-2 " >
        <v-container>
          <v-row>            
              <div v-if="status" class="inner text-center" >   
                <!-- changed in this CSS file for animation , file location: src\assets\scss\elements\_slider.scss -->            
                <h2 class="heading-title-h2">
                   We’r Full Fledged Digital Agency Creating Awesome Things
                </h2>  
                <div class="text-center mb--30 ">
              <h4  class="heading-title-h4">-WE LOVE WHAT WE DO-</h4>
            </div> 
            
            <p class="p-title-move-right">
              We develop innovative and creative products and services that provide total communication and information solutions. 
              Our emphasis is on offering a high degree of product user friendliness through a positive, creative and focused company staff.
            </p>

              </div>            
          </v-row>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
    </VisibilitySensor>
  </div>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
   import VisibilitySensor from "vue-visibility-sensor";

  export default {
    components: { VueSlickCarousel ,VisibilitySensor,},
    data() {
     return {         
        status: false,      
      };
    },
    methods: {
      onChange(isVisible) {
        if (isVisible) {
          this.status = true;
          //alert("visible");
        }
      },
    },
  };
  
</script>

<style lang="scss">
  .slick-slide {
    img {
      display: block;
      width: 100%;
    }
  }
</style>
