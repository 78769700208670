<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--2 bg-color-gray">
        <router-link to="#">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <h6 class="text-justify">
                {{ service.desc }}
              </h6>            
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  import featherOne from "vue-icon-font";
  export default {
    data() {
      return {
       /* serviceContent: [
          {
            icon: "cast",
            title: "Gravity Apps",
            desc: ` Everything was simply very well organized. Team stood out because of their swift response. FAZ team led us through the whole project. Delivered the project within the budget and timeline and even rendered some extra services.`,
            person : `Damian Laughton. Project Manger at Gravity `
          },
          {
            icon: "layers",
            title: "Axis Services",
            desc: ` We developed a great partnership with FAZ Australia and their dedication to our website is evident to all aspects. They checked in consistently easy to reach and highly flexible. We appreciate their attention to the entire project. `,
            person : `Jennifer Barbar. Business Development at Axis Services`
          },
          {
            icon: "users",
            title: "Alanic",
            desc: `Working with FAZ Australia was an outstanding experience. They brought robust experience and innovative designs . Their work on our website and digital media marketing has made a significant different to our business.`,
            person : `Tony Beig Director Sales Alanic `
          },
          {
            icon: "cast",
            title: "Gravity Apps",
            desc: ` Everything was simply very well organized. Team stood out because of their swift response. FAZ team led us through the whole project. Delivered the project within the budget and timeline and even rendered some extra services.`,
            person : `Damian Laughton. Project Manger at Gravity `
          },
          {
            icon: "layers",
            title: "Axis Services",
            desc: ` We developed a great partnership with FAZ Australia and their dedication to our website is evident to all aspects. They checked in consistently easy to reach and highly flexible. We appreciate their attention to the entire project. `,
            person : `Jennifer Barbar. Business Development at Axis Services`
          },
          {
            icon: "users",
            title: "Alanic",
            desc: `Working with FAZ Australia was an outstanding experience. They brought robust experience and innovative designs . Their work on our website and digital media marketing has made a significant different to our business.`,
            person : `Tony Beig Director Sales Alanic `
          }         
        ],*/

        serviceContent: [
          {
            icon: "layers",
            title: "Creative UI/UX Design",
            desc: ` We understand user needs and create experiences that solve design challenges and also make people happy. What makes our service unique is the mix of design, technology, and human-centered approach to produce custom visualizations, dashboards, input-intensive applications, and videos.`,            
          },
          {
            icon: "layers",
            title: "Web Development",
            desc: ` Website Design & Development, E-commerce & CMS, UX & UI Design, API & 3rd party Integrations, Responsive Design Support & Maintenance, Web & Social Apps. We create website according to your needs`,            
          },
          {
            icon: "users",
            title: "Mobile Application Development",
            desc: `Development processes have evolved. In an agile environment we have hundreds of tools available to help us conceptualize our ideas. From a small draft on paper a prototype, all the way to the to end`,            
          },
          {
            icon: "cast",
            title: "Machine Learning /AI",
            desc: `Our team of experts develops mobile and server machine learning solutions to benefit from the most innovative technological approaches.`,            
          },
          {
            icon: "layers",
            title: "Dev Ops",
            desc: ` DevOps is an essential part of any system. DevOps helps to plan the architecture for deployment of any product by which it can be available and accessible to the target audience.`,            
          },
          {
            icon: "users",
            title: "Data visualization",
            desc: `Data visualization methods refer to the creation of graphical representations of information. Plays an important part of data analytics and helps interpret big data in a real-time structure by utilising complex sets of numerical or factual figures.`,            
          }         
        ],

      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();        
      },
    },
  };
</script>
