<template>
  <v-row class="mt--10">
    <!-- Start Single Counterup  -->
    <v-col md="3" sm="3" v-for="(counter, i) in counterUpContent" :key="i">
      <div class="rn-counterup counterup_style--2">
        <h5 class="counter count">
          <VisibilitySensor @change="onChange">      
               
             <countTo
              :endVal="status ? counter.endVal : 0"
              :autoplay="countUp"
              :duration="1000"
            ></countTo>      
            
               
          </VisibilitySensor>
        </h5>         
        <h4 class="description ">
          {{ counter.desc }}
        </h4>
      </div>
    </v-col>
    <!-- Start Single Counterup  -->
  </v-row>
</template>

<script>
  import VisibilitySensor from "vue-visibility-sensor";
  import countTo from "vue-count-to";

  export default {
    components: {
      countTo,
      VisibilitySensor,
    },
    data() {
      return {
        countUp: true,
        status: false,      
        counterUpContent: [
          {
            endVal: 278,
            desc: `CUP OF TEA`,
          },
          {
            endVal: 9,
            desc: `YEARS OF EXPERIENCE`,
          },
          {
            endVal: 27650,
            desc: `HOURS OF PROJECT`,
          },
          {
            endVal: 105,
            desc: `PRODUCTS RELEASED`,
          },
        ],
      };
    },
    methods: {
      onChange(isVisible) {
        if (isVisible) {
          this.status = true;
        }
      },
    },
  };
</script>
