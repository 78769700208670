<template>
  <v-container>
    
    <v-row align="center" class="row--35">
      <v-col lg="5" md="5" cols="12">
        <div class="thumbnail">
          <slot name="thum-img"></slot>
        </div>
      </v-col>
      <v-col lg="7" md="7" cols="12">
        <div class="about-inner inner" >
          <AboutHeadingCyberTarzan />
          <div>                     
        </div>
      <!--    <v-row class="mt--30 mt_sm--10">
            <v-col lg="6" md="6" sm="12" cols="12">
              <div class="about-us-list">
                <h3 class="heading-title">
                  Who we are
                </h3>
                <p>
                  There are many vtions of passages of Lorem Ipsum available,
                  but the majority have suffered.
                </p>
              </div>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12">
              <div class="about-us-list">
                <h3 class="heading-title">
                  Who we are
                </h3>
                <p>
                  There are many vtions of passages of Lorem Ipsum available,
                  but the majority have suffered.
                </p>
              </div>
            </v-col>
          </v-row> -->
        </div>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
  import VisibilitySensor from "vue-visibility-sensor";  

  import AboutHeadingCyberTarzan from "./AboutHeadingCyberTarzan";

  export default {
    components: {      
      VisibilitySensor,
      AboutHeadingCyberTarzan
    },
    data() {
      return {        
        status: false,       
      };
    },
    methods: {
      onChange(isVisible) {
        if (isVisible) {
          this.status = true;
        //   alert("about visible");
        }
      },
    },
  };
</script>
