
<template>
  <div>   
    <VueSlickCarousel
      v-bind="settings"
      class="slider-activation rn-slick-dot rn-slick-activation dot-light mb--0"
    >   
      <div
        class="slide slide-style-2 fullscreen bg_image"
        data-black-overlay="8"
        v-for="(slider, i) in sliderContent"
        :key="i"
        :style="{ backgroundImage: 'url(' + slider.src + ')' }"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="inner text-center">
                <h1 class="heading-title">{{ slider.title }}</h1>
                <p class="description">
                  {{ slider.desc }}
                </p>
                
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";

  export default {
    components: { VueSlickCarousel },
    data() {
      return {
        /*sliderContent: [
          {
            src: require("../../assets/images/bg/bg-image-28.png"),
            title: "cyber tarzan ",
            desc: ` Design & Develop`,
          },
          {
            src: require("../../assets/images/bg/bg-image-18.jpg"),
            title: "Design",
            desc: `Research & Problem solving`,
          },
          {
            src: require("../../assets/images/bg/bg-image-27-3.jpg"),
            title: "Marketing ",
            desc: ` Digital Marketing`,
          },
        ],*/

        sliderContent: [
          {
            src: require("../../assets/images/bg/bg-image-28.png"),
            title: "web & APP development solution ",
            desc: ` covers a huge range of customized services and solutions, which include custom application development, redevelopments, re-platforming or the creation of new web applications that meet the specific needs of your business model.`,
          },
          {
            src: require("../../assets/images/bg/bg-image-18.jpg"),
            title: "Customized mobile solutions",
            desc: `Whether you are looking for an iPhone app development (iOS) or you want Android app development for your business, Cyber Tarzan can help you build your desired app with remarkable quality.`,
          },
          {
            src: require("../../assets/images/bg/bg-image-27-3.jpg"),
            title: "business analysis and consultancy ",
            desc: ` Whether you need help with business strategy development, requirements analysis or functional and technical specifications, our Business Analysts are here to help.`,
          },
        ],

        settings: {
          fade: true,
          dots: true,
          arrows: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: 20,
        },
      };
    },
  };
</script>

<style lang="scss">
  .slick-slide {
    img {
      display: block;
      width: 100%;
    }
  }
</style>
